body {
  padding: 0;
  margin: 0;
}
.footer_desc_text1{
  font-weight: 600;
  font-family: "Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer_link_banner{
  font-weight: 450;
  font-family: "Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
b{
  font-weight: 600;
  font-family: "Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.connectwithUs_block {
  text-align: center;
  font-family: "Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.address p{
  font-weight: 600;
  font-family: "Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}
@media screen and (max-width:767px){
  .address p{
    text-align: center;
    font-size:14px;
  }
}
.border {
  opacity: unset !important;
}
footer {
  background-color: #083f88;
  color: #fff;
  font-size: 12px;
  padding: 20px 0;
  padding-bottom: 3rem;
}
.ul_footer {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footcont {
  /* border-top: 1px solid #fff; */
  margin-bottom: 45px;
}
.footer_logo {
  width: 280px;
  height: 48px;
}
.ul_footer li p {
  /* margin: 0; */
  margin-bottom: 10px;
  padding: 0 15px;
  font-size: 16px;
}
.ul_footer p {
  /* margin: 0; */
  margin-bottom: 10px;
  padding: 0 15px;
  font-size: 16px;
}
.ul_footer li i {
  margin-top: 7px;
  width: 10px;
}
.ul_footer li {
  display: flex;
}
.container_l {
  padding: 0 118px;
}
.main_text_container {
  display: flex;
  font-size: 16px;
  line-height: 30px;
  padding-bottom: 36px;
  padding-top: 33px;
}
.main_text_container_left {
  width: 60%;
}
.main_text_container_left .footer_desc_text {
  width: 90%;
}
.main_text_container_right {
  width: 40%;
}
.main_text_container_right .ul_footer {
  width: 311px;
}
.footer_desc_text {
  margin-top: 0px;
  margin-top: 10px;
  font-size: 16px;
}
.footer_desc_text a {
  color: #fff;
  text-decoration: underline;
}
.padleft0 {
  padding-left: 0px !important;
}
.padright0 {
  padding-right: 0px !important;
}
.linklist {
  justify-content: flex-end;
}
.homicon {
  border-radius: 8px;
  width:79px; 
  margin: 10px;
  margin-left: 20px;
  height: 89px !important;
}
.footer_link_banner .container_l {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.footer_copyright {
  height: 62px;
  display: flex;
  align-items: center;
}
.footer_link_banner .ul_footer li {
  min-width: 40px;
}
.footer_link_banner .ul_footer li .ul_footer i {
  font-size: 23px;
  margin-top: 0px;
}
.footer_link_banner .ul_footer {
  display: flex;
  padding: 10px 0px;
}
.contact_block1{
  margin-top: 0.5rem;
}
.footer_link_banner .footer_list:nth-child(1) li {
  padding: 0 5px!important;
  border-right: solid #fff 1px;
}
.footer_link_banner .ul_footer:nth-child(1) li:last-child {
  border: none;
}
.contactus h4 {
  margin-bottom: 26px;
}
.awardsSecondCol {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.awardsReviewCol {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: end;
  padding: 0;
}
.awardrow > a:nth-child(3) img {
  margin-right: 0 !important;
}
.ehoIcon {
  max-width: 55px;
}
.reviewIcon {
  margin: 20px 0 0 20px;
  max-width: 145px !important;
  height: 75px;
}
.ehoBirdeye {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.social_footer {
  /* margin-left: 16px; */
  margin-top: 10px;
}
.social_footer i {
  font-size: 23px;
  color: #fff;
  margin-right: 20px;
}
.ul_footer li .ul_footer i {
  color: #fff;
}
.ul_footer a {
  text-decoration: none !important;
}
.ul_footer a:hover {
  text-decoration: underline !important;
}
.reviewIcon {
  max-width: 127px;
}
.birdeye {
  width: 100%;
}
.changePadMobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_block1{
  font-family: "Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.contact_block {
  /* margin-top: 8%; */
  margin-left: 10%;
}
.social_footer {
  margin: 0 auto;
}
.contactOptions {
  display: flex;
}
.email {
  display: flex;
  margin-left: inherit;
}
.copyrighttxt i {
  margin: 0px 5px;
  font-size: 18px;
}
.mobileBbbMember {
  flex-direction: column !important;
}
.cellLogoDiv {
  margin-left: auto;
}
.tacaDiv {
  justify-content: center;
}
@media (max-width: 1199px) {
  .cellLogoDiv {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .tacaDiv img {
    margin-left: 0%;
    max-width: 80%;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .tacaDiv img {
    max-width: 93%;
  }
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .copyrighttxt,
  .dislinks {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  .footer_link_banner .ul_footer {
    justify-content: center;
  }
  .footcont {
    /* border-top: 1px solid #fff; */
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 768px) {
  .bbbIcon {
    margin-right: 10px;
  }
  .footer_list {
    flex-direction: column; 
  }
  .footer_list li {
    border-right: none !important;
  }
  .footcont {
    flex-direction: column-reverse;
  }
  .footer_link_banner .ul_footer li {
    padding: 4px 0px !important;
  }
  .imagearard {
    margin-top: 20px;
  }
  .padleft0 {
    padding-left: 15px !important;
  }
  .padright0 {
    padding-right: 15px !important;
  }
  .linklist {
    justify-content: flex-start;
  }
  .awardsSecondCol {
    flex-direction: column;
  }
}
@media screen and (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1000px;
  }
}
@media screen and (max-width: 992px) {
  .birdeye {
    margin: 0 !important;
    text-align: left;
  }
  .marginImg {
    margin: 10px 20px 10px 20px !important;
    height: 40px !important;
  }
  .contactOptions{
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .marginImg {
    margin: 10px !important;
    height: 40px !important;
  }
}
@media (max-width: 767px) {
  .footer_desc_text {
    text-align: center;
  }
  .contact_block {
    margin-top: 0rem;
  }
  .ul_footer {
    text-align: center;
  }
  .email {
    display: unset !important;
    text-align: center !important;
  }
  .contactflex {
    display: unset !important;
    text-align: center !important;
  }
  .birdeye {
    width: 100%;
    margin-left: 0;
    text-align: center !important;
  }
  .homicon {
    width: 79px;
  }
  .setPad {
    padding: 1% 8%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .ehoBirdeye {
    padding-top: 30px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .memberDiv {
    text-align: center !important;
  }
  .changePadMobile {
    padding: 0;
  }
  .social_footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contactus h4 {
    text-align: center;
  }
  .logosize {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0 44%;
  }
  .footer_link_banner .ul_footer {
    font-size: 15px;
  }
  .contact_block {
    margin-left: 0;
  }
  .contactOptions {
    display: block;
  }
  .email {
    margin-left: auto;
  }
}
@media (max-width: 575px) {
  .footer_desc_text {
    text-align: center;
  }
}
@media screen and (min-width: 601px) and (max-width: 767px) {
  .ehoBirdeye {
    padding-top: unset !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .footer_link_banner .footer_list:nth-child(1) li {
    font-size: 11.3px;
    padding: 0 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .footer_link_banner .footer_list:nth-child(1) li {
    padding: 0 2px;
    border-right: solid #fff 1px;
    font-size: 10.8px;
  }
  .ul_footer p {
    margin-bottom: 10px;
    padding: 0 5px !important;
    font-size: 16px;
  }
  .email{
    margin-top: 4px;
  }
}
.birdeyeContainer > p {
  margin-bottom: 0px;
}
/* new css  */
@media screen and (min-width: 320px) and (max-width: 575px) {
  .birdeyeContainer {
    margin-top: -39px;
  }
  .homicon {
    margin-left: 40px !important;
  }
  .cellLogoImg {
    max-width: 100% !important;
    width: 245px !important;
    margin-top: 23px !important;
    height: 63px !important;
  }
  .Taca {
    max-width: 100% !important;
    height: 55px !important;
    margin-top: 30px !important;
  }
  .bbb {
    width: 118px;
    height: 76px;
    margin: 20px -22px;
  }
  .alliance {
    width: 85px;
    height: 63px;
    margin-top: 20px;
  }
  .top50 {
    width: 70px;
    margin-left: -10px;
  }
}
@media screen and (min-width: 400px) and (max-width: 575px) {
  .bbb{
    width: 140px;
  }
  .alliance{
    width: unset;
  }
  .top50 {
    margin-left: unset;
  }
}
@media screen and (min-width: 576px) and (max-width: 600px) {
  
  .Taca {
    margin-top: 9% !important;
    width: 305px !important;
    height: 49px !important;
  }
  .birdeyeContainer {
    margin-top: -40px !important;
  }
}
@media screen and (min-width: 576px) {
  .bbb {
    margin: 33px -15px !important;
  }
  .alliance {
    margin: 30px 0px 30px 30px !important;
  }
  .Taca {
    margin-top: 9%;
    width: 325px;
    height: 50px;
  }
  .cellLogoImg {
    margin-top: 20px !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .bbb {
    margin: 30px 30px 30px -31px;
  }
  .alliance {
    margin: 30px 20px;
  }
  .birdeyeContainer {
    margin-left: 156px;
  }
  .homicon {
    margin-top: 16px;
  }
  .Taca {
    margin-top: 16px;
    width: 370px !important;
    height: 50px !important;
  }
  .cellLogoImg {
    margin-top: 20px !important;
    margin-left: 56px;
   
  }
}
@media screen and (min-width: 992px) and (max-width: 1099px) {
  .Taca {
    width: 423px !important;
    height: 66px !important;
    margin-top: 7% !important;
  }
  .cellLogoImg {
    width: 295px;
    margin-top: 15px;
    height: 70px;
  }
  .bbb {
    margin: 30px !important;
    width: 160px;
    height: 93px;
  }
  .alliance {
    margin: 30px 30px 30px -21px !important;
    width: 120px;
    height: 75px;
  }
  .birdeyeContainer {
    margin: 10px 40px !important;
  }
  .homicon {
    margin: 30px 30px 30px 52px;
  }
}
@media screen and (min-width: 1100px) {
  .Taca {
    width: 467px !important;
    height: 58px !important;
    margin-top: 3.5% !important;
  }
  .cellLogoImg {
    max-width: 100%;
    width: 297px;
    margin-top: 16px;
    height: 80px;
     }
  .bbb {
    margin: 30px !important;
  }
  .alliance {
    margin: 30px 30px 30px -13px !important;
  }
  .birdeyeContainer {
    margin: 10px 26px 0px 50px !important;
  }
  .homicon {
    margin: 30px 30px 30px 60px !important;
  }  
}
@media screen and (min-width: 1200px) {
  .cellLogoImg {
    max-width: 97% !important;
    width: 274px;
  }
  .bbb {
    margin: 30px 8px !important;
  }
  .alliance {
    margin: 30px 30px 30px 22px !important;
  }
  .Taca {
    width: 448px !important;
    height: 65px !important;
    margin-left: 28px !important;
    margin-top: 8% !important;
  }
}
@media screen and (min-width: 1300px) {
  .bbb {
    margin: 30px 30px 30px 13px !important;
  }
  .alliance {
    margin: 30px 22px !important;
  }
  .birdeyeContainer {
    margin: 10px 40px 10px 70px !important;
  }
  .homicon {
    margin: 30px 30px 30px 50px !important;
  }
  .Taca {
    margin-left: 63px !important;
    width: 500px;
    height: 70px;
    margin-top: 4% !important;
  }
  .cellLogoImg {
    max-width: 100%;
    margin-top: 15px !important;
   }
}
@media screen and (min-width: 1400px) {
  .Taca {
    margin-left: 63px !important;
    width: 550px !important;
    height: 70px !important;
  }
}
@media screen and (min-width: 1500px) {
  .bbb {
    margin: 30px !important;
  }
  .alliance {
    margin: 30px !important;
  }
  .birdeyeContainer {
    margin: 10px 40px !important;
  }
  .homicon {
    margin: 30px 30px 30px -10px !important
  }
  .Taca {
    margin-left:76px !important;
    width: 536px !important;
    height: 66px !important;
    margin-top: 4% !important; 
  }

}
